.App {
  text-align: center;
}

.remote {
  position: fixed;
  top: 40%;
  right: 3%;
}

.remote ul {
  margin: 0;
  padding: 0;
  width: 15px;
  list-style: none;
}

.remote ul li{
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}

.remotebtn {
  display: block;
  position: relative;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  z-index: 1; 
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  transition: all .1s ease-in-out
}

.remotebtn.active {
  width: 100%;
  height: 100%;
}